<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-btn color="success" @click="addSpecialistModal = true">
                    Add specialist
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :class="`elevation-1 ${
                        disableHoverEffect ? 'remove-hover-effect' : ''
                    }`"
                    :key="addSpecialistModal"
                    :headers="headers"
                    :items="specialists"
                    :items-per-page="10"
                    disable-sort
                    @pagination="onPaginationChange"
                >
                    <template v-slot:body="{ items }">
                        <draggable
                            :list="items"
                            ghost-class="draggable--ghost"
                            handle=".draggable--handle"
                            tag="tbody"
                            @end="
                                changeOrder(
                                    items,
                                    pagination.page,
                                    pagination.itemsPerPage
                                )
                            "
                            @start="
                                drag = true;
                                disableHoverEffect = true;
                            "
                        >
                            <tr v-for="item in items" :key="item.id">
                                <td align="center">{{ item.id }}</td>
                                <td align="center">{{ item.first_name }}</td>
                                <td align="center">{{ item.description }}</td>
                                <td align="center">{{ item.experience }}</td>
                                <td align="center">
                                    <v-icon @click="openEditModal(item)">
                                        edit
                                    </v-icon>
                                    <v-icon @click="openDeleteModal(item)">
                                        delete
                                    </v-icon>
                                </td>
                                <td align="center">
                                    <v-icon class="draggable--handle">
                                        mdi-arrow-all
                                    </v-icon>
                                </td>
                            </tr>
                        </draggable>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <add-edit-specialist-modal
            :is-open="addSpecialistModal"
            :is-edit-mode="!!specialistToEdit"
            :specialist-to-edit="specialistToEdit"
            @added="addSpecialist"
            @updated="editSpecialist"
            @close="onClose"
        />
        <confirm-delete-modal
            :isOpen="!!specialistToDelete"
            @disagree="specialistToDelete = null"
            @agree="removeSpecialist"
        />
    </v-container>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapActions } from 'vuex';
import AddEditSpecialistModal from '@/components/specialists/AddEditSpecialistModal';
import ConfirmDeleteModal from '@/components/modals/ConfirmDeleteModal';
import ChangeOrder from '@/mixins/changeOrder.mixin';

export default {
    components: {
        AddEditSpecialistModal,
        ConfirmDeleteModal,
        Draggable
    },

    mixins: [ChangeOrder],

    data: () => ({
        disableHoverEffect: false,
        pagination: {},
        specialists: [],
        total: 0,
        headers: [
            {
                text: 'Id',
                value: 'id',
                align: 'center'
            },
            { text: 'First name', value: 'first_name', align: 'center' },
            { text: 'Description', value: 'description', align: 'center' },
            { text: 'Experience', value: 'experience', align: 'center' },
            {
                text: 'Actions',
                value: 'actions',
                align: 'center'
            },
            {
                text: 'Position',
                align: 'center'
            }
        ],
        addSpecialistModal: false,
        specialistToEdit: null,
        specialistToDelete: null
    }),

    async mounted() {
        try {
            await this.getTableItems();
        } catch (err) {
            console.error(err);

            this.$toasted.error('Something went wrong!');
        }
    },

    methods: {
        ...mapActions({
            getSpecialists: 'specialists/getSpecialists',
            deleteSpecialist: 'specialists/deleteSpecialist',
            updateOrder: 'specialists/updateOrder'
        }),

        onPaginationChange(pagination) {
            this.pagination = pagination;
        },

        async getTableItems() {
            const specialists = await this.getSpecialists();

            this.specialists = specialists.rows;
            this.total = specialists.count;
        },

        addSpecialist(specialist) {
            this.specialists = [specialist, ...this.specialists];
            this.addSpecialistModal = false;
        },

        openEditModal(specialist) {
            this.specialistToEdit = specialist;
            this.addSpecialistModal = true;
        },

        editSpecialist(specialist) {
            const editingIndex = this.specialists.findIndex(
                s => s.id === specialist.id
            );

            if (editingIndex !== -1) {
                this.specialists[editingIndex] = { ...specialist };
            }

            this.specialistToEdit = null;
            this.addSpecialistModal = false;
        },

        openDeleteModal(specialist) {
            this.specialistToDelete = specialist;
        },

        async removeSpecialist() {
            try {
                await this.deleteSpecialist(this.specialistToDelete.id);

                const specialistIndex = this.specialists.findIndex(
                    specialist => {
                        return specialist.id === this.specialistToDelete.id;
                    }
                );

                this.specialists.splice(specialistIndex, 1);

                this.specialistToDelete = null;

                this.$toasted.success('Specialist has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        },

        onClose() {
            this.addSpecialistModal = false;
            this.specialistToEdit = null;
        }
    }
};
</script>
