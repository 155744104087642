import { required } from 'vuelidate/lib/validators';

export default {
    validations: {
        specialist: {
            first_name: { required },
            description: { required },
            experience: { required }
        }
    },
    computed: {
        firstNameErrors() {
            const errors = [];
            if (!this.$v.specialist.first_name.$dirty) return errors;
            !this.$v.specialist.first_name.required &&
                errors.push('First name is required.');

            return errors;
        },

        descriptionErrors() {
            const errors = [];
            if (!this.$v.specialist.description.$dirty) return errors;
            !this.$v.specialist.description.required &&
                errors.push('Description is required.');

            return errors;
        },

        experienceErrors() {
            const errors = [];
            if (!this.$v.specialist.experience.$dirty) return errors;
            !this.$v.specialist.experience.required &&
                errors.push('Experience is required.');

            return errors;
        }
    }
};
