var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.addSpecialistModal = true}}},[_vm._v(" Add specialist ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.addSpecialistModal,class:("elevation-1 " + (_vm.disableHoverEffect ? 'remove-hover-effect' : '')),attrs:{"headers":_vm.headers,"items":_vm.specialists,"items-per-page":10,"disable-sort":""},on:{"pagination":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"body",fn:function(ref){
                var items = ref.items;
return [_c('draggable',{attrs:{"list":items,"ghost-class":"draggable--ghost","handle":".draggable--handle","tag":"tbody"},on:{"end":function($event){return _vm.changeOrder(
                                items,
                                _vm.pagination.page,
                                _vm.pagination.itemsPerPage
                            )},"start":function($event){_vm.drag = true;
                            _vm.disableHoverEffect = true;}}},_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.id))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.first_name))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.description))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.experience))]),_c('td',{attrs:{"align":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.openEditModal(item)}}},[_vm._v(" edit ")]),_c('v-icon',{on:{"click":function($event){return _vm.openDeleteModal(item)}}},[_vm._v(" delete ")])],1),_c('td',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"draggable--handle"},[_vm._v(" mdi-arrow-all ")])],1)])}),0)]}}])})],1)],1),_c('add-edit-specialist-modal',{attrs:{"is-open":_vm.addSpecialistModal,"is-edit-mode":!!_vm.specialistToEdit,"specialist-to-edit":_vm.specialistToEdit},on:{"added":_vm.addSpecialist,"updated":_vm.editSpecialist,"close":_vm.onClose}}),_c('confirm-delete-modal',{attrs:{"isOpen":!!_vm.specialistToDelete},on:{"disagree":function($event){_vm.specialistToDelete = null},"agree":_vm.removeSpecialist}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }